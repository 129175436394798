import React, {lazy, Suspense} from 'react';

import loading from './assets/img/icons/loading.gif';

import './assets/css/loading.css';

const App = lazy(() => import('./App'));

const Stackios = () => {
    return (
        <Suspense fallback={
            <div className="loader-container">
                <div><img className="loader" src={loading} alt=' ' /></div>
            </div>
        }>
            <App />
        </Suspense>
        );
    }

export default Stackios;